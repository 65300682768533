import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './Roadmap.scss';

const Roadmap = () => {
	return (
		<div className="cd_roadmap">
			<div className="cd_roadmap_header">Roadmap</div>
			<hr />
			<ScrollAnimation animateIn="slideInRight">
				<div className="cd_roadmap_details">
					<div className="cd_roadmap_item">
						<img src="/images/location-icon.svg" alt="location" />
						<div className="cd_roadmap_indicator active"></div>
						<div className="cd_roadmap_time">Q1-2023</div>
						<div className="cd_roadmap_title">CasperDash Web</div>
						<div className="cd_roadmap_content">Launch CasperDash Web Wallet Testnet</div>
					</div>

					<div className="cd_roadmap_item ">
						<img src="/images/location-icon.svg" alt="location" />
						<div className="cd_roadmap_indicator active current"></div>
						<div className="cd_roadmap_time">Q2-2023</div>
						<div className="cd_roadmap_title">Audit</div>
						<div className="cd_roadmap_content">Audit Mobile Apps & Extension​</div>
						<div className="cd_roadmap_content">Publish the useWallet library​</div>
					</div>

					<div className="cd_roadmap_item ">
						<img src="/images/location-icon.svg" alt="location" />
						<div className="cd_roadmap_indicator active current"></div>
						<div className="cd_roadmap_time">Q3-2023</div>
						<div className="cd_roadmap_title">DApps support</div>
						<div className="cd_roadmap_content">Support opening & connecting to DApps in mobile apps</div>
						<div className="cd_roadmap_content">Fiat on-ramps and off-ramps</div>
					</div>

					<div className="cd_roadmap_item current">
						<img src="/images/location-icon.svg" alt="location" />
						<div className="cd_roadmap_indicator"></div>
						<div className="cd_roadmap_time">End-of-2023</div>
						<div className="cd_roadmap_title">Swapping & NFT Marketplace</div>
						<div className="cd_roadmap_content">Swap tokens</div>
						<div className="cd_roadmap_content">List NFTs</div>
					</div>
				</div>
			</ScrollAnimation>
		</div>
	);
};

export default Roadmap;
