import React from 'react';
import { newTab } from '../utils/newTab';
import './Contact.scss';

const Contact = () => {
	return (
		<div className="cd_contact">
			<div className="cd_contact_header">Want To Contact Us?</div>
			<div className="cd_contact_text">Get In Touch</div>
			<div className="cd_contact_actions">
				<div className="cd_contact_logo">
					<img
						className="cd_logo"
						alt="github"
						src="/images/github-icon.svg"
						onClick={() => newTab('github')}
					/>
				</div>
				<div className="cd_contact_logo">
					<img
						className="cd_logo"
						alt="telegram"
						src="/images/telegram.svg"
						onClick={() => newTab('telegram')}
					/>
				</div>
				<div className="cd_contact_logo">
					<img
						className="cd_logo"
						alt="twitter"
						src="/images/twitter.svg"
						onClick={() => newTab('twitter')}
					/>
				</div>
			</div>
			<div className="mt-4 flex gap-4 md:mt-8">
				<a href="https://casperdash.io/privacy-policy.html" className="underline">
					Privacy Policy
				</a>
				<a href="https://casperdash.io/terms-of-use.html" className="underline">
					Terms of Use
				</a>
			</div>
			<div className="cd_copy_right mt-8 md:mt-12">
				Powered By Block8910
				<span className="cr_logo">&#9426;</span>
			</div>
		</div>
	);
};

export default Contact;
