import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import CasperStats from './components/CasperStats';
import KeyFeatures from './components/KeyFeatures';
import Ecosystem from './components/Ecosystem';
import Roadmap from './components/Roadmap';
import AboutUS from './components/AboutUS';
import Contact from './components/Contact';
import Partnership from './components/Partnership';
import './App.css';

function App() {
	useEffect(() => {
		ReactGA.initialize('G-0TLRE686SQ');
	});
	return (
		<div className="App">
			<Header />
			<MainContent />
			<CasperStats />
			<KeyFeatures />
			<Ecosystem />
			<Roadmap />
			<AboutUS />
			<Partnership />
			<Contact />
		</div>
	);
}

export default App;
