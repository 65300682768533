import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './AboutUS.scss';

const AboutUS = () => {
	return (
		<div className="cd_about_us">
			<div className="cd_about_title">
				<div>About US</div>
				<img alt="casper-network" src="/images/casper-network.png" />
			</div>
			<ScrollAnimation animateIn="fadeInUp" offset={0}>
			<div className="cd_about_us_details">
				<div className="cd_about_us_details_item">
					<div className="cd_about_us_details_title">Our Culture</div>
					<div className="cd_about_us_details_content">
						CasperDash is being created by a young, focused, eager team with a strong IT background and full
						of energy. <br />
						We believe that great ideas can come from anywhere, regardless of seniority and experience.
						<br />
						Embrace creativity and collaborative problem solving, we aim to create incredible outcomes.
					</div>
				</div>
				<div className="cd_about_us_details_item">
					<div className="cd_about_us_details_title">Our Goal</div>
					<div className="cd_about_us_details_content">
						Our goal is to go fast and far in providing technology to Casper Community. <br />
						We are not afraid to make tough choices that align with our long-term objectives.
					</div>
				</div>
				<div className="cd_about_us_details_item">
					<div className="cd_about_us_details_title">Awards</div>
					<div className="cd_about_us_details_content">
						CasperDash has won Casper Friendly Hakathon 2021 and Now, funded by DEVxDAO Association to
						develop Casper Wallet.
					</div>
				</div>
			</div>
			</ScrollAnimation>
		</div>
	);
};

export default AboutUS;
