import React from 'react';

const PRIORITY_PARTNERSHIP = [
	{ name: 'EggForce', img: '/images/eggforceLogo.webp', width: '250px', height: '', url: 'https://eggforce.io' },
];

const PARTNERSHIP = [
	{
		name: 'origin stake',
		img: '/images/originstake.png',
		width: '200px',
		height: '',
		url: 'https://originstake.com/',
	},

	{
		name: 'C-Stake',
		img: 'https://assets.casperdash.io/partner--cstake.1a6d6d217313115aa2b1.webp',
		width: '',
		height: '80px',
	},

	{ name: 'Ever Stake', img: '/images/everstakeLogo.svg', width: '200px', height: '', url: 'https://everstake.one/' },
	{
		name: 'Forest Staking',
		img: '/images/forestStakingLogo.svg',
		height: '60px',
		text: 'Forest Staking',
		url: 'https://foreststaking.com/',
	},
	{
		name: 'Speedy Staking',
		img: 'https://s3.ap-southeast-1.amazonaws.com/assets.eggforce.io/SpeedyXVCasperStaking-logo.webp',
		height: '60px',
		url: 'https://speedyxvcasperstaking.one/',
	},
	{
		name: 'Bit Cat',
		img: 'https://assets.casperdash.io/bit-cat-logo.svg',
		height: '60px',
		url: 'https://www.bitcat365.com/',
	},
	{
		name: 'Casper Delegation',
		img: 'https://assets.eggforce.io/casper-delegation-square.webp',
		width: '120px',
		url: '',
	},
	{
		name: 'CasperCommunity.io',
		img: 'https://assets.eggforce.io/casper-community-io.webp',
		width: '120px',
		url: 'https://casperCommunity.io',
	},
	{
		name: 'Make',
		img: 'https://assets.eggforce.io/make.webp',
		width: '120px',
		url: 'https://makestake.io/',
	},
	{
		name: 'Cure53',
		img: 'https://assets.casperdash.io/cure53-dark.png',
		width: '200px',
		height: '',
		url: 'https://cure53.de/',
	},
	{
		name: 'DotOracle',
		img: '/images/dotOracleLogo.png',
		width: '180px',
		height: '',
		url: 'https://dotoracle.network/',
	},
	{
		name: 'Nexfi',
		img: 'https://nexfi.app/logo.svg',
		width: '180px',
		height: '',
		url: 'https://nexfi.app/',
	},
];

const Partnership = () => {
	const openNewWindow = (url) => {
		window.open(url, '_blank');
	};
	return (
		<div className="mb-20 flex items-center justify-center flex-col">
			<div className="text-5xl font-semibold">Partnership</div>
			<div className="mt-20 flex items-center gap-[80px] flex-wrap items-center justify-center">
				{PRIORITY_PARTNERSHIP.map((item) => {
					return (
						<div
							style={{
								width: item.width,
								height: item.height,
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer',
								flexDirection: 'column',
							}}
							onClick={() => openNewWindow(item.url)}
						>
							<img className="w-full h-full" src={item.img} alt={item.name} />
							<p>{item.text}</p>
						</div>
					);
				})}
			</div>
			<div className="mt-5 flex items-center gap-[110px] flex-wrap items-center justify-center">
				{PARTNERSHIP.map((item) => {
					return (
						<div
							style={{
								width: item.width,
								height: item.height,
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer',
								gap: '8px',
								flexDirection: item.texDirection,
							}}
							onClick={() => openNewWindow(item.url)}
						>
							<img className="w-full h-full" src={item.img} alt={item.name} />
							<p>{item.text}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Partnership;
