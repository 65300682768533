import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import './KeyFeatures.scss';

const FEATURES = [
	{
		key: 'nft',
		image: '/images/nft-icon.png',
		title: 'NFTs',
		content: 'CasperDash offers you an easy way to keep all your unique CEP47 crypto NFTs in one place. Love your crypto NFTs? We love them too. Store them all in your CasperDash.'
	},
	{
		key: 'staking',
		image: '/images/staking-icon.png',
		title: 'Staking',
		content: 'Staking your CSPR to all Casper validators and earning rewards in a few taps with CasperDash.'
	},
	{
		key: 'keyManager',
		image: '/images/keyManager-icon.png',
		title: 'Key Management',
		content: 'Other essential features include an account permissions model that allows the recovery of lost keys and a permissions model to securely share state between accounts and contracts (without expensive cryptographic checks).'
	}
]

const KeyFeatures = () => {
	return (
		<div className="cd_key_features content">
			{FEATURES.map(({ key, image, title, content }) => {
				return <ScrollAnimation animateIn="fadeInUp" key={key}>
					<div className="cd_key_features_item">
						<img src={image} alt={key} />
						<div>
							<div className="cd_key_features_title">{title}</div>
							<div className="cd_key_features_content">
								{content}
							</div>
						</div>
					</div>
				</ScrollAnimation>
			})}
		</div>

	);
};

export default KeyFeatures;
