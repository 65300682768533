import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toFormattedNumber, toFormattedCurrency, nFormatter } from '../utils/format';
import './CasperStats.scss';

const URL = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=casper-network';
const CasperStats = () => {
	const [CSPRInfo, setCSPRInfo] = useState({});

	useEffect(() => {
		axios.get(URL).then(({ data }) => {
			setCSPRInfo(data[0] ? data[0] : {});
		});
		const interval = setInterval(() => {
			if (document && document.visibilityState === 'visible') {
				axios.get(URL).then(({ data }) => {
					setCSPRInfo(data[0] ? data[0] : {});
				});
			}
		}, 60000);
		return () => clearInterval(interval);
	}, [useState]);

	return (
		<div className="cd_casper_stats">
			<div className="cd_casper_stats_content">
				<div className="cd_casper_stat_item">
					<div className="cd_casper_stat_label">Casper</div>
					<img className="cd_casper_stat_value" alt="cspr" src="/images/cspr-icon.png" />
				</div>
				<div className="cd_casper_stat_item">
					<div className="cd_casper_stat_label">Price</div>
					<div className="cd_casper_stat_value">
						<div className="cd_casper_stat_main_value">
							{toFormattedCurrency(CSPRInfo.current_price, { maximumFractionDigits: 4 })}
						</div>
						<div
							className={`cd_casper_stat_sub_value ${
								CSPRInfo.price_change_percentage_24h < 0 ? 'negative' : 'positive'
							}`}
						>
							{toFormattedNumber(CSPRInfo.price_change_percentage_24h / 100, {
								style: 'percent',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</div>
					</div>
				</div>
				<div className="cd_casper_stat_item">
					<div className="cd_casper_stat_label">Marketcap</div>
					<div className="cd_casper_stat_value">${nFormatter(CSPRInfo.market_cap, 2)}</div>
				</div>
				<div className="cd_casper_stat_item">
					<div className="cd_casper_stat_label">Total Supply</div>
					<div className="cd_casper_stat_value">{nFormatter(CSPRInfo.total_supply, 2)}</div>
				</div>
				<div className="cd_casper_stat_item">
					<div className="cd_casper_stat_label">24h Volume</div>
					<div className="cd_casper_stat_value">${nFormatter(CSPRInfo.total_volume, 2)}</div>
				</div>

				<div className="cd_casper_stat_item">
					<div className="cd_casper_stat_label">Circulating supply</div>
					<div className="cd_casper_stat_value">${nFormatter(CSPRInfo.circulating_supply, 2)}</div>
				</div>
			</div>
		</div>
	);
};

export default CasperStats;
