import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Button from './common/Button';
import { newTab } from '../utils/newTab';
import './MainContent.scss';

const MainContent = () => {
	return (
		<div className="cd_main_content content">
			<div className="cd_main_content_left">
				<ScrollAnimation animateIn="slideInLeft" className="animate_left">
					<div className="cd_main_content_header">A non-custodial wallet built in Casper blockchain</div>
					<div className="cd_main_content_details">
						Store, send and receive tokens on Casper ecosystem with CasperDash. Also, CasperDash is
						available for storing all digital assets as NFTs and tokens​
					</div>
					<div className="cd_main_content_actions">
						{/* <Button text="Add To Chrome​" onClick={() => newTab('chrome_extension')} /> */}

						<Button text="Access Web Wallet" className="primary" onClick={() => newTab('web_wallet')} />
					</div>
					<div className="cd_application">
						<img src="/images/apple-store.png" alt="apple-store" onClick={() => newTab('apple_app')} />
						<img src="/images/google-store.png" alt="google-store" onClick={() => newTab('google_app')} />
						<img
							src="/images/chrome-app.png"
							alt="apple-store"
							onClick={() => newTab('chrome_extension')}
						/>
					</div>
				</ScrollAnimation>
			</div>

			<div className="cd_main_content_right" offset={0}>
				<ScrollAnimation animateIn="slideInLeft">
					<img src="/images/casperhand.png" alt="casper-hand" />
				</ScrollAnimation>
			</div>
		</div>
	);
};

export default MainContent;
