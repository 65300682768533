import React from 'react';
import './Button.scss';

const Button = ({ text, className, onClick }) => {
	return (
		<div className={`cd_btn ${className}`} onClick={onClick}>
			<span>{text}</span>
		</div>
	);
};

export default Button;
