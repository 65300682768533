import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import './Ecosystem.scss';

const EcoSystem = () => {
	return (
		<div className="cd_ecosystem">
			<div className="cd_ecosystem_container">
				<ScrollAnimation animateIn="bounceInLeft" className="ecosystem_animate">
					<div className="cd_ecosystem_content">
						<div className="cd_ecosystem_header">Casper Ecosystem</div>
						<div className="cd_ecosystem_item">
							<div className="cd_ecosystem_item_header">Easy To Connect To Casper Blockchain</div>
							<div className="cd_ecosystem_item_content">
								CasperDash allows you to read balance, send transactions and manage Casper accounts from one
								application.​
							</div>
						</div>
						
						<div className="cd_ecosystem_item">
							<div className="cd_ecosystem_item_header">
								Available on Mobile, Desktop & Browser Extension​
							</div>
							<div className="cd_ecosystem_item_content">
								CasperDash is now accessible as a desktop, a mobile app in each app store, and a browser
								plugin.​
							</div>
						</div>
					</div>
				</ScrollAnimation>
				<ScrollAnimation animateIn="bounceInRight" className="phone_image">
					<img src="/images/phone.png" alt="phone" />
				</ScrollAnimation>
			</div>
		</div>
	);
};

export default EcoSystem;
