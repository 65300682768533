import React from 'react';
import Button from './common/Button';
import { newTab } from '../utils/newTab';
import './Header.scss';

const Header = () => {
	return (
		<div className="w-full flex flex-col items-center justify-center md:flex-row md:justify-between md:px-8">
			<img
				src="/images/official-casperdashlogo.png"
				alt="casperdash-logo"
				className="w-[80%] mt-4 md:w-[240px]"
			/>
			<div className="flex flex-col items-center gap-4 mt-4 md:flex-row">
				<div className="flex gap-4">
					<img
						className="cd_social cursor-pointer"
						src="/images/github-icon.svg"
						alt="github"
						onClick={() => newTab('github')}
					/>
					<img
						className="cd_social cursor-pointer"
						src="/images/telegram.svg"
						alt="telegram"
						onClick={() => newTab('telegram')}
					/>
					<img
						className="cd_social cursor-pointer"
						src="/images/twitter.svg"
						alt="twitter"
						onClick={() => newTab('twitter')}
					/>
				</div>
				<div className="flex gap-2">
					<Button className="secondary" text="Docs" onClick={() => newTab('docs')} />
					<Button text="Download Wallet" onClick={() => newTab('chrome_extension')} />
				</div>
			</div>
		</div>
	);
};

export default Header;
