import ReactGA from 'react-ga4';
const TELEGRAM_URL = 'https://t.me/casperdash';
const TWITTER_URL = 'https://twitter.com/casperdash_io';
const CHROME_EXTENSION_DOWNLOAD =
	'https://chrome.google.com/webstore/detail/casperdash/hmfpdofehnmfnoaneplbcpejindkoafd';
const WEB_WALLET = 'https://wallet.casperdash.io';
const APPLE_APP = 'https://apps.apple.com/vn/app/casperdash/id1605920901';
const GOOGLE_APP = 'https://play.google.com/store/apps/details?id=io.casperdash.casperwallet';
const GITHUB = 'https://github.com/CasperDash';
const DOCS = 'https://docs.casperdash.io/';

const URL_MAPPING = {
	telegram: TELEGRAM_URL,
	twitter: TWITTER_URL,
	chrome_extension: CHROME_EXTENSION_DOWNLOAD,
	web_wallet: WEB_WALLET,
	google_app: GOOGLE_APP,
	apple_app: APPLE_APP,
	github: GITHUB,
	docs: DOCS,
};

export const newTab = (type) => {
	let url = URL_MAPPING[type] || WEB_WALLET;
	ReactGA.event({
		category: type,
		action: `click on ${type}`,
	});
	window.open(url, '_blank');
};
